export enum idConditionDG {
  pregnant = '4818de8a-6b5c-493f-be3d-5d2be27a159d',
  pregnantBull = '9c64f205-df7c-4185-b5fa-9ae97c6007a8',
  pregnantDiscard = 'ab382b95-2238-4ad0-b74e-846f43799b39',
  emptyDiscard = '64e25d17-25f2-40d4-82b7-75f94b217c04',
  empty = '2cb063f6-1217-47f2-b43c-ca5d39c09291',
}

export enum idConditionDGFinal {
  pregnantIATF = '6515a4d6-a8ab-4650-a7a1-0f1b74c930ab',
  pregnantBull = '04aa36f9-1114-49a2-9045-3fd8c0489143',
  pregnantIAFTDiscard = 'deb77800-0266-4541-938a-5ee0fdd32912',
  pregnantBullDiscard = 'f2526284-9a1e-4427-959f-5e816c72f20e',
  emptyDiscard = 'cda5bfed-055f-48e0-84c4-2a39ea075c60',
  empty = 'de8ff47f-1a49-459f-9ed7-20919a84a864',
}

export enum idClOfEmpty {
  no = '18321d58-9f62-439d-afb1-36f635603143',
  yes = '279990bf-8190-42af-b596-4310e7e684cb',
  notEvaluated = 'fd967cb3-5e5e-4bc4-891f-92e571450464',
}

export enum idPregnantType {
  twinning = '2861c04d-041a-495c-81a6-e6ee676795f3',
  absorbing = '01f3eecb-24fe-4f7b-862d-b799909f477a',
  normal = 'fac7be10-3733-4880-87bc-2403a90ec287',
}

export enum idECC {
  one = 'ab15fb33-8a0a-44ae-bab4-c42bb3afb688',
  oneTwentyFive = 'a8804839-3fd1-4c1b-b5d5-faa1ef1c5ddb',
  oneAndFive = 'f4e9c9ad-0640-4706-a16c-9dec7753bb9e',
  oneAndSeventyFive = '4d09f502-0862-4f52-bb70-55df757f9904',
  two = '21885485-9d8e-4dbd-9a45-a470e59dec34',
  twoTwentyFive = 'c53aeb91-b72e-41ef-ade4-5dde1eb77e5d',
  twoAndFive = 'ca0be2b4-9189-4a33-9891-65a042ccbe0b',
  twoAndSeventyFive = 'dddcfdc2-5250-48f5-8063-3342f34e41b5',
  three = 'bc53dd51-1859-4621-99af-f22d5cbd3dc0',
  threeTwentyFive = '2a243dbd-19ff-4c69-8498-4f8351192997',
  threeAndFive = '7d6ff84a-a63e-43a3-9caf-edf4dffbb8a3',
  threeAndSeventyFive = 'd65d5d5f-8c3f-4155-aa79-c17ae6d1b2db',
  four = 'f2bfd0f2-7415-4447-bda0-dd4e9c5c3229',
  fourTwentyFive = 'c2557cdb-8627-4bd1-9eed-1b2cffd66278',
  fourAndFive = '16b5424b-d636-436b-966d-e4b6d4e3cf8c',
  fourAndSeventyFive = 'b688f854-977a-40b6-ace2-d81429c4ed6f',
  five = '3100a185-e55f-4862-b58c-c4585ff9e18e',
}

export enum idProtocolManagementId {
  dayOfWithdrawal = 'a86b93bd-d29c-4cab-860c-66cfdab859a3',
  calfRemoval = 'd56b8881-dfe3-410a-b506-5c7ab762683f',
  ovulatoryStimulation = 'e8ba98bc-bf33-4834-9e29-859fd81f47b5',
  estradiolBenzoate = '9fc2f8cb-3d3a-4e1a-95fe-88139d579263',
  injectableProgesterone = '7e4faa7f-65bc-49c0-ba2c-2e3312ab14e3',
  iatf = '5dc3e373-0dd8-4ba3-b23b-c8b5b0f78a0e',
  luteólise = '5ee4dca9-8b4c-47ce-8d66-a5b8b9fdc2f5',
  finalStimulus = 'dbab37e8-7345-40b1-a76d-f11d3b6fcbe1',
}
