import * as sync from '../../src/syncWorker'

import {
  ALL_FIELDS_TO_EXPORT,
  POWER_BI_FIELDS_TO_EXPORT,
} from '../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../database'
import Autocomplete, { autocompleteDefaultOnChange } from '../components/material/Autocomplete'
import Button, { COLORS } from '../components/material/Button'
import DateField, {
  datefieldDefaultOnChange
} from '../components/material/DateField'
import { ESTADOS, ESTADOS_E_CIDADES } from '../utils/constants'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from './../components/ResponsiveTable'
import { USUARIO, formatName, isDateNullOrValid, missingRequiredFieldChecker } from '../utils/formHelper'
import { endLoading, startLoading } from './../redux/screen/actions'

import AddIcon from '@material-ui/icons/Add'
import BuildIcon from '@material-ui/icons/Build'
import CallMissedOutgoingRoundedIcon from '@material-ui/icons/CallMissedOutgoingOutlined'
import { Container } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { LocalStorageHelper } from '../utils/localStorageHelper'
import MainContainer from '../components/MainContainer'
import MobileCard from '../components/MobileCard'
import Prompt from '../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SyncIcon from '@material-ui/icons/Sync'
import TextField from '../components/material/TextField'
import TitleBar from '../components/TitleBar'
import TopBar from '../components/TopBar'
import TrainingsButton from './Trainings/TrainingsButton'
import _ from 'lodash'
import { changeSyncView } from '../redux/sync/actions'
import { connect } from 'react-redux'
import downloadimg from '../../src/assets/icons/icon-download.png'
import { findEstado } from '../utils/formHelper'
import { getLoggedUser } from '../redux/auth/actions'
import { getUserFile } from './../redux/reports/actions'
import hasInternet from './../utils/recognizeInternetConnection'
import moment from 'moment'
import { sortList } from '../utils/helper'
import track from 'react-tracking'
import { verify } from '../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'

@track(() => ({ page: 'Home', date: new Date() }))
class Home extends Component {
  constructor(props) {
    super(props)
    const sortedFarms = sortList(props.farms, 'nome')
    // const selectedEstacaoDeMonta = props.estacaoMonta.filter(eM => eM.descricao == "current")[0]
    // ? props.estacaoMonta.filter(eM => eM.descricao == "current")[0]?.id
    // : props.estacaoMonta[0]?.id
    const selectedEstacaoDeMonta = !!LocalStorageHelper.get(
      'selectedEstacaoDeMonta'
    )
      ? LocalStorageHelper.get('selectedEstacaoDeMonta')
      : props.estacaoMonta.filter((eM) => eM.descricao === 'current')[0]?.id

    this.state = {
      allFarms: sortedFarms,
      selectedFarms: selectedEstacaoDeMonta
        ? sortedFarms.filter((sf) => sf.estacaoMonta === selectedEstacaoDeMonta)
        : sortedFarms,
      selectedFarmsToSyncAll: sortedFarms,
      corrals: [],
      batches: [],
      cowCount: [],
      selectedToSyncFarms: [],
      forceSyncConfirmation: false,
      noConnection: false,
      noAlertSyncPull: localStorage.getItem('noAlertSyncPull') === 'true',
      alertSyncPull24h: this.getStatusAlertSyncPull24h(),
      selectedEstacaoDeMonta,
      total: 0,
      cooldownMessage: null,
      duplicateFarmsNamesMessage: `As Fazendas duplicadas foram: ${LocalStorageHelper?.get(
        'duplicateFarmsNames'
      )?.replace(',', ', ')}`,
      downloadFarm: null,
      syncFarms: false,
      alert: false,
      fazendasBaixadasIds: [],
      confirmationAdjustFarm: false,
      isConnected: hasInternet(),
      farmClicked: null,
      file: false,
      showFields: false,
      allFieldsOptionsExport: ALL_FIELDS_TO_EXPORT,
      selectedFieldsOptionsExport:
        JSON.parse(LocalStorageHelper.get('ProGerarSavedFieldsToExport')) ?? [],
      isAdmin: this.props.loggedUser.roles.includes('Administrador'),
      filtersVisible: false,
      filters: {
        nomeFilter: '',
        estadoFilter: null,
        municipioFilter: '',
        retiroFilter: null,
        inicioEstacaoMontaFilters: null,
        fimEstacaoMontaFilters: null
      }
    }

    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleFilter = this.handleFilter.bind(this)

    this.getCowsByFarmId()
  }

  componentDidMount() {
    if (!!!LocalStorageHelper.get('ProGerarSavedFieldsToExport')) {
      LocalStorageHelper.add(
        'ProGerarSavedFieldsToExport',
        JSON.stringify(ALL_FIELDS_TO_EXPORT)
      )
    }
  }

  getStatusAlertSyncPull24h() {
    const lastSyncPull = localStorage.getItem('lastSyncPull')
    const diference = new Date() - new Date(lastSyncPull)
    if (diference >= 86400000) {
      //24h
      return true
    }
    return false
  }

  async getCowsByFarmId() {
    let corrals = await utils.getWithParam(
      AVAILABLE_ENTITIES.CORRALS,
      'farm_id',
      Q.oneOf(this.props.farms.map((b) => b.id))
    )

    this.setState({ corrals })

    let batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'corral_id',
      Q.oneOf(this.state.corrals.map((b) => b.id))
    )

    this.setState({ batches })

    let vacas = await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(this.state.batches.map((b) => b.id))
    )

    this.setState({ vacas })

    this.state.batches.map(
      (b) => (b.vacas = this.state.vacas.filter((v) => v.loteId === b.id))
    )
    this.state.corrals.map(
      (c) =>
      (c.batches = this.state.batches.filter(
        (b) =>
          b.retiroId === c.id &&
          b.originalBatchId == null &&
          b.deleted === false
      ))
    )
    this.state.selectedFarms.map(
      (f) =>
        (f.corrals = this.state.corrals.filter((c) => c.fazendaId === f.id))
    )

    this.setState({
      cowCount: this.state.selectedFarms.map((f) => this.getCount(f)),
    })

    this.getTotalCowsNumber(this.state.selectedEstacaoDeMonta)
  }

  getCount(farm) {
    const batches = farm.corrals.map((c) => c.batches)
    let count = {
      farmId: farm.id,
      countNumber: 0,
      estacaoMonta: farm.estacaoMonta,
    }
    batches.forEach((b) => {
      b.forEach((element) => {
        count.countNumber += element.vacas.length
      })
    })

    return count
  }

  handleToggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
    this.cleanFilters();
  };

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedFarms: this.state.allFarms })
    } else {
      const selectedFarms = this.state.allFarms.filter(
        (x) => x.nome.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedFarms })
    }
  }

  applyFilters() {
    const { nomeFilter, estadoFilter, municipioFilter, retiroFilter, inicioEstacaoMontaFilters, fimEstacaoMontaFilters } = this.state.filters;
    let filteredFarms = this.state.allFarms;

    // Filtrar por data inicial estação de monta
    if (!_.isEmpty(inicioEstacaoMontaFilters)) {
      filteredFarms = filteredFarms.filter(
        (data) => data.inicioEstacaoMonta.format('DD/MM/YY') === moment(inicioEstacaoMontaFilters).format('DD/MM/YY')
      );
    }

    // Filtrar por data inicial estação de monta
    if (!_.isEmpty(inicioEstacaoMontaFilters)) {
      filteredFarms = filteredFarms.filter(
        (data) => data.inicioEstacaoMonta.format('DD/MM/YY') === moment(inicioEstacaoMontaFilters).format('DD/MM/YY')
      );
    }

    // Filtrar por data final estação de monta
    if (!_.isEmpty(fimEstacaoMontaFilters)) {
      filteredFarms = filteredFarms.filter(
        (data) => data.fimEstacaoMonta.format('DD/MM/YY') === moment(fimEstacaoMontaFilters).format('DD/MM/YY')
      );
    }


    // Filtrar pelo nome do proprietário
    if (!_.isEmpty(nomeFilter)) {
      filteredFarms = filteredFarms.filter(
        (x) => x.nomeProprietario.toLowerCase().includes(nomeFilter.toLowerCase())
      );
    }

    // Filtrar pelo estado
    if (estadoFilter) {
      filteredFarms = filteredFarms.filter(
        (data) => data.estado === estadoFilter.id
      );
    } else {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          municipioFilter: ''
        }
      }))
    }

    // Filtrar pela cidade
    if (!_.isEmpty(municipioFilter)) {
      filteredFarms = filteredFarms.filter(
        (data) => data.municipio === municipioFilter
      );
    }

    // Filtrar pelo retiro
    if (retiroFilter) {
      filteredFarms = filteredFarms.filter(
        (data) => data.retiro === retiroFilter.value
      );
    }

    this.setState({ selectedFarms: filteredFarms });
  }

  cleanFilters() {
    this.setState({
      filters: {
        nomeFilter: '',
        estadoFilter: null,
        municipioFilter: '',
        retiroFilter: null,
        inicioEstacaoMontaFilters: null,
        fimEstacaoMontaFilters: null
      }
    });
    this.setState({
      selectedFarms: this.state.selectedEstacaoDeMonta
        ? this.state.allFarms.filter((sf) => sf.estacaoMonta === this.state.selectedEstacaoDeMonta)
        : this.state.allFarms,
    })
  }

  @track((props, state) => ({
    action: 'Fez um filtro por estação de monta',
    value: state.selectedEstacaoDeMonta,
  }))
  handleFilter(value) {
    if (!value) {
      this.setState({ selectedEstacaoDeMonta: null })
      LocalStorageHelper.remove('selectedEstacaoDeMonta')
      this.setState({ selectedFarms: this.state.allFarms })
      this.getCowsByFarmId()
      return
    }
    this.setState({ selectedEstacaoDeMonta: value.id })
    LocalStorageHelper.add('selectedEstacaoDeMonta', value.id)
    this.getCowsByFarmId()
    this.filterDataByEM(value.id)
  }

  filterDataByEM(value) {
    let selectedFarms = this.state.allFarms.filter(
      (sf) => sf.estacaoMonta === value
    )
    this.setState({ selectedFarms })
  }

  getTotalCowsNumber(estacaoMonta) {
    let totalCountNumber = 0

    if (this.state.cowCount.length > 0) {
      if (estacaoMonta) {
        for (let cow of this.state.cowCount) {
          if (cow.estacaoMonta === estacaoMonta) {
            totalCountNumber += cow.countNumber
          }
        }
      } else {
        for (let cow of this.state.cowCount) {
          totalCountNumber += cow.countNumber
        }
      }
    }

    this.setState({ total: totalCountNumber.toLocaleString('pt-BR') })
  }

  verifyFarmsIds(farmId) {
    if (localStorage.getItem('downloadFarms')) {
      if (localStorage.getItem('downloadFarms').indexOf(farmId) >= 0) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  @track((props, state, farm) => ({
    action: 'Clicou no nome da fazenda',
    value: farm[0].id,
  }))
  handleEntryInFarm = (row) => {
    ; (row.corrals && row.corrals.length > 0) || this.verifyFarmsIds(row.id)
      ? this.props.history.push(`/farms/${row.id}`)
      : this.setState({ downloadFarm: row.id })
  }

  @track((props, state, farm) => ({
    action: 'Clicou no botao de Sincronizar fazenda',
    value: farm[0].id,
  }))
  handleSyncFarm = (row) => {
    this.SyncFarm([row.id])
  }

  @track((props, state, farm) => ({
    action: 'Clicou no botao de Editar fazenda',
    value: farm[0].id,
  }))
  handleEditFarm = (row) => {
    this.props.history.push(`/farms/${row.id}/update`)
  }

  getTableColumnsData() {
    return [
      {
        name: 'Fazenda',
        sortBy: true,
        type: CELL_TYPES.CHECKBOX,
        checked: (row) => this.state.selectedToSyncFarms.indexOf(row.id) >= 0,
        label: (row) => row.nome,
        onClick: (row) => {
          return () => {
            let selectedToSyncFarms = this.state.selectedToSyncFarms
            selectedToSyncFarms.indexOf(row.id) >= 0
              ? selectedToSyncFarms.splice(
                selectedToSyncFarms.indexOf(row.id),
                1
              )
              : selectedToSyncFarms.push(row.id)
            this.setState({ selectedToSyncFarms })
          }
        },
        onClickLink: (row) => {
          return () => this.handleEntryInFarm(row)
        },
        disabled: (row) =>
          this.state.selectedToSyncFarms.length >= 10 &&
          !(this.state.selectedToSyncFarms.indexOf(row.id) >= 0),
      },
      {
        name: 'Município/UF',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) =>
          `${row.municipio}/${row.estado ? row.estado : row.pais}`,
      },
      {
        name: 'Estação de Monta',
        sortBy: true,
        type: CELL_TYPES.TEXT,
        label: (row) =>
          row.estacaoMonta
            ? this.props.estacaoMonta.filter(
              (eM) => eM.id === row.estacaoMonta
            )[0].valor
            : '',
      },
      {
        name: 'Período da EM',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          row.inicioEstacaoMonta && row.fimEstacaoMonta
            ? `${row.inicioEstacaoMonta.format(
              'DD/MM/YY'
            )} - ${row.fimEstacaoMonta.format('DD/MM/YY')}`
            : '',
      },
      {
        name: 'Retiro',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) => `${row.retiro ? 'Sim' : 'Não'}`,
      },
      {
        name: 'Matrizes',
        sortBy: true,
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.verifyFarmsIds(row.id)
            ? this.state.cowCount.length > 0
              ? this.state.cowCount
                .filter((cc) => cc.farmId === row.id)[0]
                ?.countNumber.toLocaleString('pt-BR')
              : 0
            : '-',
      },
      {
        type: CELL_TYPES.ICON_BUTTON,
        icon: (row) =>
          (row.corrals && row.corrals.length > 0) ||
            this.verifyFarmsIds(row.id) ? (
            <SyncIcon style={{ fontSize: 23 }} />
          ) : (
            <GetAppRoundedIcon
              style={{
                color: `${this.state.alert && '#EA5D0D'}`,
                fontSize: 23,
              }}
            />
          ),
        label: (row) =>
          (row.corrals && row.corrals.length > 0) || this.verifyFarmsIds(row.id)
            ? 'Sincronizar Fazenda'
            : 'Baixar Dados',
        onClick: (row) => () => this.handleSyncFarm(row),
      },
      {
        type: CELL_TYPES.ICON_BUTTON,
        icon: (row) => <BuildIcon style={{ fontSize: 23 }} />,
        label: (row) => 'Ajustar fazenda',
        onClick: (row) => {
          return () =>
            this.setState({ confirmationAdjustFarm: true, farmClicked: row })
        },
        disabled: (row) =>
          ((row.corrals && row.corrals.length > 0) || this.verifyFarmsIds(row.id)) && this.props.currentSync === 2
            ? false
            : true,
      },
      {
        type: CELL_TYPES.ICON_BUTTON,
        icon: (row) => <EditIcon style={{ fontSize: 23 }} />,
        label: (row) => 'Editar',
        onClick: (row) => () => this.handleEditFarm(row),
      },
    ]
  }

  getMobileData() {
    return {
      title: 'Fazenda',
      values: this.state.selectedFarms.map((item) => ({
        Fazenda: item.nome,
        'Nome do Proprietário': `${item.nomeProprietario.split(' ')[0]} ${!!item.nomeProprietario.split(' ')[1]
          ? item.nomeProprietario.split(' ')[1]
          : ''
          }`,
        'Município/UF': `${item.municipio}/${item.estado ? item.estado : item.pais
          }`,
        'Estação de Monta': item.estacaoMonta
          ? this.props.estacaoMonta.filter(
            (eM) => eM.id === item.estacaoMonta
          )[0].valor
          : '-',
        'Período da EM':
          item.inicioEstacaoMonta && item.fimEstacaoMonta
            ? `${item.inicioEstacaoMonta.format(
              'DD/MM/YY'
            )} - ${item.fimEstacaoMonta.format('DD/MM/YY')}`
            : '-',
        Retiro: `${item.retiro ? 'Sim' : 'Não'}`,
        Matrizes: this.verifyFarmsIds(item.id)
          ? this.state.cowCount.length > 0
            ? this.state.cowCount
              .filter((cc) => cc.farmId === item.id)[0]
              ?.countNumber.toLocaleString('pt-BR')
            : 0
          : '-',
        Actions: _.compact([
          {
            label: '',
            checked: this.state.selectedToSyncFarms.indexOf(item.id) >= 0,
            disabled:
              this.state.selectedToSyncFarms.length >= 10 &&
              !(this.state.selectedToSyncFarms.indexOf(item.id) >= 0),
            onClick: () => {
              let selectedToSyncFarms = this.state.selectedToSyncFarms
              selectedToSyncFarms.indexOf(item.id) >= 0
                ? selectedToSyncFarms.splice(
                  selectedToSyncFarms.indexOf(item.id),
                  1
                )
                : selectedToSyncFarms.push(item.id)
              this.setState({ selectedToSyncFarms })
            },
          },
          {
            icon: <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />,
            label: 'Entrar na Fazenda',
            onClick: () => this.handleEntryInFarm(item),
          },
          {
            icon:
              (item.corrals && item.corrals.length > 0) ||
                this.verifyFarmsIds(item.id) ? (
                <SyncIcon style={{ fontSize: 23 }} />
              ) : (
                <GetAppRoundedIcon
                  style={{
                    color: `${this.state.alert && '#EA5D0D'}`,
                    fontSize: 23,
                  }}
                />
              ),
            label:
              (item.corrals && item.corrals.length > 0) ||
                this.verifyFarmsIds(item.id)
                ? 'Sincronizar Fazenda'
                : 'Baixar Dados',
            onClick: () => this.handleSyncFarm(item),
          },
          {
            type: CELL_TYPES.ICON_BUTTON,
            icon: <BuildIcon style={{ fontSize: 23 }} />,
            label: 'Ajustar fazenda',
            disabled:
              ((item.corrals && item.corrals.length > 0) ||
                this.verifyFarmsIds(item.id)) && this.props.currentSync === 2
                ? false
                : true,
            onClick: () =>
              this.setState({
                confirmationAdjustFarm: true,
                farmClicked: item,
              }),
          },
          {
            icon: <EditIcon style={{ fontSize: 23 }} />,
            label: 'Editar',
            onClick: () => this.handleEditFarm(item),
          },
        ]),
      })),
      noDataMessage: 'Ainda não existe nenhuma fazenda.',
    }
  }

  async initSync() {
    const farmsIds = []
    this.state.selectedFarmsToSyncAll.map(
      (farm) => this.verifyFarmsIds(farm.id) && farmsIds.push(farm.id)
    )

    if (hasInternet()) {
      this.props.startLoading()
      if (farmsIds.length > 0) {
        await sync.pullFarms(farmsIds).then(() => {
          this.getCowsByFarmId()
          this.props.endLoading()
        })
      } else {
        await sync.forceSync(false, false).then(() => this.props.endLoading())
      }
    } else {
      this.setState({ noConnection: true })
    }
    this.setState({ syncFarms: true })
  }

  async SyncFarm(farms) {
    if (hasInternet()) {
      this.props.startLoading()
      await sync.pullFarms(farms).then(() => {
        this.getCowsByFarmId()
        this.props.endLoading()
      })
      farms.map((farm) =>
        this.setState((prevState) => {
          return prevState.fazendasBaixadasIds.push(farm)
        })
      )
    } else {
      this.setState({ noConnection: true })
    }
    this.setState({
      selectedToSyncFarms: [],
      downloadFarm: null,
    })
  }

  @track((props, state, farm) => ({
    action: 'Confirmou o Ajuste de fazenda',
    value: farm[0].id,
  }))
  async cleanFarms(farm) {
    if (hasInternet()) {
      this.props.startLoading()
      const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
      const response = await repository.cleanDataToWatermelon(farm)

      await sync.pullFarms([farm.id], {}, false, false, true)

      this.props.endLoading()

      this.setState({ farmClicked: null })

      if (response.success) {
        setTimeout(function () {
          window.location.reload()
        }, 500)
      }
    }
  }

  @track({ action: 'Clicou no botao de sincronização' })
  handleSync = () => this.initSync()

  @track({ action: 'Entrou na página de estação de monta' })
  handleToPageIniciarEstacoDeMonta = () =>
    this.props.history.push('/farms/estacaodemonta')

  @track({ action: 'Clicou no botao de o Backup' })
  handleToBackup = () => {
    const selectedEstacaoDeMonta = this.props.estacaoMonta
      .find((item) => item.id === this.state.selectedEstacaoDeMonta)

    sync.downloadBackup(
      'Backup ' +
      `${formatName(this.props.user.nomeCompleto, USUARIO)} ${!!this.state.selectedEstacaoDeMonta
        ? ` EM ${selectedEstacaoDeMonta.valor}`
        : ' EM Geral'}` +
      '.zip',
      this.state.selectedEstacaoDeMonta ?? null
    )
  }

  @track({ action: 'Clicou no botao de Exportar' })
  handleToExportData = (selectedFieldsOptionsExport, isPowerBI = false) => {
    if (hasInternet()) {
      getUserFile(
        this.props.user.id,
        selectedFieldsOptionsExport,
        isPowerBI
          ? `${formatName(this.props.user.nomeCompleto, USUARIO)}-powerbi`
          : formatName(this.props.user.nomeCompleto, USUARIO),
        this.state.selectedEstacaoDeMonta
      )
    } else {
      this.setState({ noConnection: true })
    }
  }

  @track({ action: 'Entrou na página de nova fazenda' })
  handleToNewFarm = () => this.props.history.push('/farms/create')

  @track((props, state, farm) => ({
    action: 'Sincronizou fazendas selecionadas',
    value: farm[0],
  }))
  handleSyncSelectedFarms = (selectedFarms) => this.SyncFarm(selectedFarms)

  getButtons() {
    return [
      {
        onClick: this.handleSync,
        label: 'SINCRONIZAR',
        icon: <SyncIcon></SyncIcon>,
      },
      {
        onClick: this.handleToBackup,
        label: 'BACKUP',
      },
      {
        onClick: this.handleToPageIniciarEstacoDeMonta,
        label: 'Iniciar estação de monta',
      },
      {
        hasDropdown: true,
        buttons: [
          {
            onClick: () => this.handleToExportData([]),
            label: `${this.state.selectedEstacaoDeMonta
              ? `Exportar EM ${this.props.estacaoMonta.find(
                (item) => item.id === this.state.selectedEstacaoDeMonta
              ).valor
              }`
              : 'Exportação Geral'
              } `,
          },
          {
            onClick: () => this.setState({ showFields: true }),
            label: 'Exportação Personalizada',
          },
          {
            onClick: () =>
              this.handleToExportData(POWER_BI_FIELDS_TO_EXPORT, true),
            label: 'Exportação para o POWER BI',
          },
        ],
        label: 'Exportação',
      },
      {
        onClick: this.handleToNewFarm,
        icon: <AddIcon />,
        label: 'Fazenda',
      },
    ]
  }

  verifyDownloadFarms() {
    if (this.state.fazendasBaixadasIds.length === 10) {
      this.setState({ alert: false })
      return false
    }
    return true
  }

  render() {
    return (
      <Container>
        <TopBar title={''} />
        <TitleBar
          title={`Bem vindo(a), ${this.props.user.nomeCompleto}`}
          buttons={this.getButtons()}
        />
        <TrainingsButton page='/' />
        <MainContainer
          containerTitle='Fazendas'
          containerSubtitle={`Matrizes Baixadas: ${this.state.total}`}
          hasSearchWithFilter
          filterProps={this.props.estacaoMonta}
          defaultFilterValue={this.state.selectedEstacaoDeMonta}
          filterLabel='Estação de Monta (EM)'
          searchLabel='Buscar'
          handleSearch={this.handleSearch}
          handleFilter={this.handleFilter}
          hasSearchWithFilterButtton
          handleButtonFilter={this.handleToggleFilters}
        >
          {this.state.filtersVisible && (
            <div className='grid grid-batch-form'>
              <div className='grid-item p-12 header-info-left'>
                <p className='p-font-weight-700'>Outros Filtros</p>
              </div>
              <div className='grid-item p-12 t-6 d-3'>
                <TextField
                  id='nomeFilter'
                  label='Nome do Proprietário'
                  type='text'
                  onChange={(e, value) => {
                    const nomeFilter = e.target.value;
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        nomeFilter
                      }
                    }), this.applyFilters);
                  }}
                  value={this.state.filters.nomeFilter || ''}
                />
              </div>
              <div className='grid-item p-12 t-6 d-3'>
                <Autocomplete
                  id='retiroFilter'
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('retiroFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        retiroFilter: value
                      }
                    }), this.applyFilters);
                  }}
                  value={this.state.filters.retiroFilter || null}
                  onKeyDown={this.state.filters.retiroFilter && this.onEnterPress}
                  getOptionLabel={(option) => option.label}
                  label='Retiro'
                />
              </div>
              <div className="grid-item p-12 t-6 d-3">
                <Autocomplete
                  id='estadoFilter'
                  options={ESTADOS}
                  getOptionLabel={(option) => {
                    if (_.has(option, 'name')) {
                      return option.name
                    }
                    const opt = findEstado(option)
                    return (opt && opt.name) || ''
                  }}
                  label='Estado'
                  value={this.state.filters.estadoFilter || ''}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('estadoFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        estadoFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className="grid-item p-12 t-6 d-3">
                <Autocomplete
                  id='municipioFilter'
                  label='Município'
                  options={
                    this.state.filters.estadoFilter
                      ? ESTADOS_E_CIDADES.find(
                        (e) =>
                          e.name === this.state.filters.estadoFilter.name ||
                          e.id === this.state.filters.estadoFilter.id
                      )?.cidades || []
                      : []
                  }
                  disabled={!this.state.filters.estadoFilter}
                  onChange={(e, value) => {
                    this.autocompleteDefaultOnChange('municipioFilter')
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        municipioFilter: value
                      }
                    }), this.applyFilters);
                  }}
                />
              </div>
              <div className='grid-item p-12 t-6'>
                <DateField
                  id='inicioEstacaoMonta'
                  label='Início da Estação de Monta'
                  value={this.state.filters.inicioEstacaoMontaFilters || null}
                  onChange={(value) => {
                    this.datefieldDefaultOnChange('inicioEstacaoMonta', value)
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        inicioEstacaoMontaFilters: value
                      }
                    }), this.applyFilters);
                  }}
                  // onKeyDown={this.onEnterPress}
                  error={
                    !_.isEmpty(this.state.inicioEstacaoMonta_error) ||
                    !this.isDateNullOrValid(this.state.filters.inicioEstacaoMontaFilters)
                  }
                  helperText={this.state.inicioEstacaoMonta_error}
                  disabled={this.props.disabled}
                />
              </div>
              <div className='grid-item p-12 t-6'>
                <DateField
                  id='fimEstacaoMonta'
                  label='Fim da Estação de Monta'
                  value={this.state.filters.fimEstacaoMontaFilters || null}
                  onChange={(value) => {
                    this.datefieldDefaultOnChange('fimEstacaoMonta', value)
                    this.setState(prevState => ({
                      filters: {
                        ...prevState.filters,
                        fimEstacaoMontaFilters: value
                      }
                    }), this.applyFilters);
                  }}
                  onKeyDown={this.onEnterPress}
                  error={
                    !_.isEmpty(this.state.fimEstacaoMonta_error) ||
                    !this.isDateNullOrValid(this.state.filters.fimEstacaoMontaFilters)
                  }
                  helperText={this.state.fimEstacaoMonta_error}
                />
              </div>
              <div className="grid-item p-12 p-display-flex p-justify-space-between">
                <Button
                  color={COLORS.GREY}
                  label={'Limpar'}
                  type='button'
                  startIcon={<DeleteIcon />}
                  onClick={() => { this.cleanFilters() }}
                />
              </div>
            </div>
          )}
          {window.innerWidth > 767 ? (
            <ResponsiveTable
              columns={this.getTableColumnsData()}
              rowsPerPage={10}
              data={this.state.selectedFarms}
              className='table-home'
              onSort={(sortedData) => this.setState({ selectedFarms: sortedData })}
            />
          ) : (
            <MobileCard data={this.getMobileData()} />
          )}
        </MainContainer>

        <Prompt
          visible={this.state.forceSyncConfirmation}
          title='Aviso!'
          message={`Este botão deve ser utilizado apenas nos casos em que os dados já sincronizados por outro usuário não foram atualizados em sua conta. Ao confirmar esta ação, você só poderá forçar uma nova sincronização após o período de 1 hora. Deseja prosseguir?`}
          buttons={[
            {
              label: 'Cancelar',
              onClick: () => this.setState({ forceSyncConfirmation: false }),
            },
            {
              label: 'Confirmar',
              onClick: () => {
                this.setState({ forceSyncConfirmation: false })
                sync.refreshApplication().then((res) => {
                  if (res != null) {
                    this.setState({ cooldownMessage: res })
                  }
                })
              },
            },
          ]}
        />

        <Prompt
          visible={
            !localStorage.getItem('downloadFarms') && this.state.syncFarms
          }
          title='Baixe os dados das fazendas!'
          message={`Antes de realizar a sincronização baixe os dados 
            das fazendas que deseja clicando no botão de download:`}
          image={downloadimg}
          buttons={[
            {
              label: 'Ok',
              onClick: () =>
                this.setState({
                  syncFarms: false,
                  alert: true,
                }),
            },
          ]}
        />

        <Prompt
          visible={verify()}
          title='Página duplicada!'
          message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
          buttons={[
            {
              autoFocus: false,
            },
          ]}
        />

        <Prompt
          visible={this.state.showFields}
          title='Escolha os campos que deseja visualizar na exportação'
          className='hidewhenprinting'
          message={
            <div className='grid-item p-12 d-3 hidewhenprinting'>
              <Autocomplete
                multiple
                id='allFieldsOptionsExport'
                defaultValue={
                  JSON.parse(
                    LocalStorageHelper.get('ProGerarSavedFieldsToExport')
                  ) ?? this.state.allFieldsOptionsExport
                }
                onChange={(_, value) => {
                  this.setState({
                    selectedFieldsOptionsExport: value.map((item) => item),
                  })
                  LocalStorageHelper.add(
                    'ProGerarSavedFieldsToExport',
                    JSON.stringify(value)
                  )
                }}
                filterSelectedOptions
                options={this.state.allFieldsOptionsExport}
                getOptionLabel={(option) => option}
                style={{ width: '100%', backgroundColor: 'white' }}
              />
            </div>
          }
          leftButton={{
            label: 'Redefinir',
            onClick: () => {
              this.setState({
                selectedFieldsOptionsExport: [],
                showFields: false,
              })
              LocalStorageHelper.add(
                'ProGerarSavedFieldsToExport',
                JSON.stringify(ALL_FIELDS_TO_EXPORT)
              )
            }
          }}
          buttons={[
            {
              label: 'Fechar',
              onClick: () => this.setState({ showFields: false }),
            },
            {
              label: 'Exportar',
              onClick: () => {
                this.handleToExportData(this.state.selectedFieldsOptionsExport)
                this.setState({ showFields: false })
              },
            },
          ]}
        />

        <Prompt
          visible={this.state.noConnection}
          title='Não é possível realizar esta ação!'
          message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ noConnection: false }),
            },
          ]}
        />

        <Prompt
          visible={this.state.downloadFarm}
          title='Atenção!'
          message={`É necessário realizar o download dos dados dessa fazenda para acessá-la.
          Clique no OK para baixar a fazenda!`}
          buttons={[
            {
              label: 'Cancelar',
              onClick: () => this.setState({ downloadFarm: null }),
            },
            {
              label: 'Ok',
              onClick: () => this.SyncFarm([this.state.downloadFarm]),
            },
          ]}
        />

        <Prompt
          visible={!this.state.noAlertSyncPull}
          title='Bem vindo!'
          message={`Antes de começar utilizar o ProGerar, realize o download das fazendas que você utilizará através do botão de download ao lado da fazenda. 
            Você também pode utilizar as caixas de seleção para baixar várias fazendas simultaneamente.
            Lembre-se de sincronizar o seu ProGerar diariamente para manter tudo em ordem.`}
          buttons={[
            {
              label: 'Não avisar novamente',
              onClick: () => {
                localStorage.setItem('noAlertSyncPull', true)
                this.setState({ noAlertSyncPull: true })
              },
              autoFocus: false,
            },
            {
              label: 'Ok',
              onClick: () => this.setState({ noAlertSyncPull: true }),
            },
          ]}
        />

        <Prompt
          visible={
            !verify() &&
            this.state.alertSyncPull24h &&
            this.state.noAlertSyncPull &&
            !this.state.isAdmin
          }
          title='Lembre de sincronizar seus dados!'
          message={`Seu ProGerar foi sincronizado há mais de 24h. Reforçamos a importância de manter o seu ProGerar atualizado diariamente e recomendamos que isso seja feito antes do seu uso em campo para realizar os manejos. Para realizar esta ação, seu dispositivo precisa estar conectado a internet e você pode utilizar o botão de Sincronizar localizado na parte superior da tela inicial.`}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ alertSyncPull24h: false }),
              autoFocus: false,
            },
          ]}
        />

        <Prompt
          visible={this.state.cooldownMessage != null}
          title='Ação ainda não disponível!'
          message={this.state.cooldownMessage}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ cooldownMessage: null }),
            },
          ]}
        />

        <Prompt
          visible={this.state.cooldownMessage != null}
          title='Ação ainda não disponível!'
          message={this.state.cooldownMessage}
          buttons={[
            {
              label: 'Ok',
              onClick: () => this.setState({ cooldownMessage: null }),
            },
          ]}
        />

        <Prompt
          visible={
            !!LocalStorageHelper.get('duplicateFarmsNames') &&
            this.state.duplicateFarmsNamesMessage !== null
          }
          title='Fazendas Duplicadas para Estação de Monta Atual!'
          message={this.state.duplicateFarmsNamesMessage}
          buttons={[
            {
              label: 'Ok',
              onClick: () => {
                LocalStorageHelper.remove('duplicateFarmsNames')
                this.setState({ duplicateFarmsNamesMessage: null })
              },
            },
          ]}
        />

        <Prompt
          visible={this.state.confirmationAdjustFarm}
          title='Atenção!'
          message='Ao confirmar essa ação, você irá limpar os dados dessa fazenda e fazer uma sincronização limpa baixando os dados do servidor, deseja continuar?'
          buttons={[
            {
              label: 'Cancelar',
              onClick: () =>
                this.setState({
                  confirmationAdjustFarm: false,
                  farmClicked: null,
                }),
            },
            {
              label: 'Confirmar',
              onClick: () => this.cleanFarms(this.state.farmClicked),
            },
          ]}
        />

        {this.state.selectedToSyncFarms.length > 0 && (
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '50%',
              marginLeft: '-185px',
              width: '370px',
              padding: '10px',
              background: 'white',
              border: '1.75px solid #EEE',
              borderRadius: '5px',
              zIndex: 1,
            }}
          >
            <Button
              label='Sincronizar Fazendas Selecionadas'
              onClick={() =>
                this.handleSyncSelectedFarms(this.state.selectedToSyncFarms)
              }
            />
            <br />

            <span>{`Máximo de 10 fazendas por vez, ${10 - this.state.selectedToSyncFarms.length
              } fazendas restantes.`}</span>
          </div>
        )}

        {this.state.alert &&
          this.verifyDownloadFarms() &&
          !this.state.selectedToSyncFarms.length > 0 && (
            <div
              style={{
                position: 'fixed',
                bottom: '10px',
                left: '50%',
                marginLeft: '-185px',
                width: '370px',
                padding: '10px',
                background: 'white',
                border: '1.75px solid #EEE',
                borderRadius: '5px',
                zIndex: 1,
                textAlign: 'center',
              }}
            >
              <br />

              <span>
                <h2 style={{ fontSize: '15px' }}>
                  Baixe os dados da fazenda clicando no botão:
                </h2>
                <img
                  src={downloadimg}
                  alt='download'
                  width={'30px'}
                  height={'30px'}
                  color='#EA5D0D'
                />
              </span>
            </div>
          )}
      </Container>
    )
  }
}

async function getFarms(user, isAdministrador) {
  if (!user) {
    return []
  }

  const repository = new Repository(AVAILABLE_ENTITIES.FARMS)
  const farmsData = isAdministrador
    ? await repository.get()
    : await repository.getByParam('user_id', user.id)
  const relatedFarmsData = isAdministrador
    ? { success: true, response: [] }
    : await repository.getByRelation('farms__rel__users', 'user_id', user.id)

  if (farmsData.success && relatedFarmsData.success) {
    return _.uniqBy(
      farmsData.response.concat(relatedFarmsData.response),
      (farm) => farm.id
    )
  }
  return []
}

const propsFromDatabase = async (props) => {
  const loggedUser = await getLoggedUser()
  const user = await utils.getElement(
    AVAILABLE_ENTITIES.USERS,
    loggedUser.userId
  )
  const isAdministrator = _.find(
    loggedUser.roles,
    (el) => el === 'Administrador'
  )

  return {
    user,
    loggedUser,
    estacaoMonta: await utils.getDomainValuesBy('Estação de Monta'),
    farms: await getFarms(user, isAdministrator),
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  changeSyncView: (payload) => dispatch(changeSyncView(payload)),
})

const mapStateToProps = (state) => ({
  currentSync: state.sync.currentSync,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(Home)))
